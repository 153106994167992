import React, { Component } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import {
  FaTwitter,
  FaTelegramPlane,
  FaYoutube,
  FaGithub,
  FaFileAlt,
  FaMedium,
  FaDiscord,
} from "react-icons/fa";
const logoUrl = (
  <img src="/assets/images/logo/logo-powercity.gif" alt="Powercity" />
);

const SocialShare = [
  {
    Social: <FaTelegramPlane />,
    link: "https://t.me/POWERCITYio/",
    text: "Telegram",
  },
  {
    Social: <FaTwitter />,
    link: "https://twitter.com/POWERCITYio/",
    text: "Twitter",
  },
  {
    Social: <FaYoutube />,
    link: "https://www.youtube.com/channel/UCKoFHpAen2bjhqp4Vzq6ZRQ",
    text: "YouTube",
  },
  //{Social: <FaGithub /> , link: '#', text: 'GitHub'},
  {
    Social: <FaMedium />,
    link: "https://medium.com/@powercity",
    text: "Medium",
  },
  {
    Social: <FaFileAlt />,
    link: "https://docs.powercity.io/",
    text: "Documentation",
  },
];

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area footer-style-01 bg_color--1">
          {/* Start Call to Action Area  */}
          {/* <div className="im-call-to-action-area ptb--70 im-separator">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-8 col-xl-6 col-md-12 col-sm-12 col-12">
                                    <div className="inner">
                                        <h2 className="text-white mb--0">Stay up to date with the latest news!</h2>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-xl-4 offset-xl-2 col-md-12 col-sm-12 col-12">
                                    <div className="call-to-cation-tbn text-left text-lg-right mt_md--20 mt_sm--20">
                                        <a target="_blank" className="btn-default btn-md btn-border btn-opacity" href="https://twitter.com/POWERCITYio/">Visit Twitter</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
          {/* End Call to Action Area  */}

          {/* Start Footer Area  */}
          <div className="footer-wrapper ptb--70">
            <div className="container">
              <div className="row">
                <div className="col-lg-1 col-md-6 col-sm-6 col-12">
                  <div className="ft-text"></div>
                </div>

                {/* Start Single Widget  */}
                <div className="col-lg-3 col-md-3 col-sm-12 mt_mobile--40">
                  <div className="footer-link">
                    <h4>Quick Links</h4>
                    <ul className="ft-link">
                      <li>
                        <ScrollLink
                          activeClass="active"
                          to="apps"
                          smooth={true}
                          offset={50}
                          duration={500}>
                          CORE
                        </ScrollLink>
                      </li>
                      <li>
                        <ScrollLink
                          activeClass="active"
                          to="apps"
                          smooth={true}
                          offset={50}
                          duration={500}>
                          PortalX Swap
                        </ScrollLink>
                      </li>
                      <li>
                        <ScrollLink
                          activeClass="active"
                          to="apps"
                          smooth={true}
                          offset={50}
                          duration={500}>
                          EARN Protocol
                        </ScrollLink>
                      </li>
                      <li>
                        <ScrollLink
                          activeClass="active"
                          to="apps"
                          smooth={true}
                          offset={50}
                          duration={500}>
                          FLEX Protocol
                        </ScrollLink>
                      </li>
                      <li>
                        <ScrollLink
                          activeClass="active"
                          to="apps"
                          smooth={true}
                          offset={50}
                          duration={500}>
                          PixelPark Marketplace
                        </ScrollLink>
                      </li>
                      {/* <li>
                        <ScrollLink
                          activeClass="active"
                          to="apps"
                          smooth={true}
                          offset={50}
                          duration={500}>
                          Accelerator
                        </ScrollLink>
                      </li>  */}
                      <li>
                        <ScrollLink
                          activeClass="active"
                          to="apps"
                          smooth={true}
                          offset={50}
                          duration={500}>
                          Amplifier
                        </ScrollLink>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widget  */}

                {/* Start Single Widget  */}
                <div className="col-lg-4 col-md-3 col-sm-12 mt_md--40 mt_sm--40">
                  <div className="footer-link">
                    <h4>POWERCITY</h4>
                    <ul className="ft-link">
                      <li>
                        <ScrollLink
                          activeClass="active"
                          to="home"
                          smooth={true}
                          offset={0}
                          duration={500}>
                          Home
                        </ScrollLink>
                      </li>
                      <li>
                        <ScrollLink
                          activeClass="active"
                          to="about"
                          smooth={true}
                          offset={50}
                          duration={500}>
                          About
                        </ScrollLink>
                      </li>
                      <li>
                        <ScrollLink
                          activeClass="active"
                          to="team"
                          smooth={true}
                          offset={50}
                          duration={500}>
                          Team
                        </ScrollLink>
                      </li>
                      <li>
                        <ScrollLink
                          activeClass="active"
                          to="stats"
                          smooth={true}
                          offset={50}
                          duration={500}>
                          Stats
                        </ScrollLink>
                      </li>
                      <li>
                        <ScrollLink
                          activeClass="active"
                          to="tech"
                          smooth={true}
                          offset={50}
                          duration={500}>
                          Technologies
                        </ScrollLink>
                      </li>
                      <li>
                        <a target="_blank" href="https://docs.powercity.io/">
                          Documentation
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          download
                          href="/assets/downloads/POWERCITY-Branding-Assets-v01.zip">
                          Branding Assets
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widget  */}

                {/* Start Single Widget  */}
                <div className="col-lg-4 col-md-3 col-sm-12 mt_md--40 mt_sm--40">
                  <div className="footer-link">
                    <h4>Join or follow us!</h4>

                    <div className="social-share-inner mt--20">
                      <ul className="social-share social-style--2 d-flex justify-content-start liststyle">
                        {SocialShare.map((val, i) => (
                          <li key={i}>
                            <a href={`${val.link}`}>{val.Social}</a>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <br />
                    <p>
                      Copyright © {new Date().getFullYear()}{" "}
                      <Link to="/">POWERCITY</Link>
                      <br /> All rights reserved.
                    </p>

                    <div className="logo">
                      <Link to="/">{logoUrl}</Link>
                    </div>
                  </div>
                </div>
                {/* End Single Widget  */}
              </div>
            </div>
          </div>
          {/* End Footer Area  */}
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
